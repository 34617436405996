.ResumePage {
    /* background-image: url("../images/bg.jpg"); */
    background-color: whitesmoke;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: scroll;
}

.Main {
    background-color: white;
    position: relative;
    width: 60vw;
    margin-top: 5vw;
    margin-left: 20vw;
    margin-right: 20vw;
    margin-bottom: 5vw;
    padding: 20px 20px 20px 20px;
}

.Main h1 {
    text-align: left;
    color: slategrey;
    font-weight: 400;
    float: left;
}

.titleContainer  {
    display: flex;
    flex-direction: row; 
}

.curJobTitle {
    position: absolute;
    vertical-align: bottom;
    top: 32px;
    right: 20px;
}

.Main h3 {
    min-height: 100%;
    text-align: right;
    color: slategrey;
    font-weight: 400;
    margin-left: auto;
    align-self: flex-end;
}

.Main h2 {
    text-align: left;
    color: slategrey;
    font-weight: 400;
}

.Main hr {
    clear: both;
    position: relative; 
    top: 0px; 
    border: none; 
    height: 2px; 
    background: slategrey; 
    margin-bottom: 15px; 
}

.Main p {
    clear: both;
    text-align: left;
}

.Main h4 {
    text-align: left;
    font-weight: 450;
}

.Main h5 {
    text-align: left;
    float: left;
    font-weight: 400;
}

.Main h6 {
    text-align: right;
    float: right;
}