.LandingPage {
    background-color: white;
}

.ParallaxText {
    color: white;
    font-weight: 300;
    text-align: center;
    align-self: center;
    vertical-align: middle;
}